import React, { useState } from 'react'
import { createCase } from '../services/strapiCreate'
import { getUser } from '../services/auth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Link } from 'gatsby'


function ContactForm() {
  const [validated, setValidated] = useState(false)
  const [subject, setSubject] = useState('General')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [strapiCase, setStrapiCase] = useState({})

  const handleSubmit = (event) => {
    try {
      let reader = new FileReader()
      reader.readAsDataURL(event.target.fileId.files[0])
      console.log('file reader',reader)
    } catch(e) {
      console.error(e)
    }

    const form = event.currentTarget;
    event.preventDefault()
    event.stopPropagation()
    setValidated(true);
    if (form.checkValidity()) {
      createCase({
        subject: subject,
        description: event.target.message.value,
        name: event.target.name.value,
        email: event.target.email.value,
        created_by_user_id: getUser()?.id
      }).then(res => {
        setIsSubmitted(true)
        setStrapiCase(res)
        setStrapiCase(res)
      })
    } else {
      console.error('nah')
    }
  }

  const handleRadioChange = event => {
    setSubject(event.target.id)
  }

  if (!isSubmitted)
    return (
      <Form noValidate validated={validated} onSubmit={event => {
        handleSubmit(event)
      }}>
        <Form.Row>
          <Form.Group as={Col} controlId="name">
            <Form.Label className="mb-0 form-label_small">Name</Form.Label>
            <Form.Control
              className="text-input_plain fill-div"
              required
              type="text"
              placeholder="Jonny Appleseed"
              defaultValue={getUserFullName()}
            />
            <Form.Control.Feedback type="invalid">Please enter your name</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label className="mb-0 form-label_small">Email</Form.Label>
            <Form.Control
              className="text-input_plain fill-div"
              required
              type="text"
              placeholder="jonny.appleseed@example.com"
              defaultValue={getUser()?.email}
            />
            <Form.Control.Feedback type="invalid">Please enter your email address</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="subject">
            <Form.Label className="mb-0 form-label_small">Subject</Form.Label>
            <Form.Check
              type="radio"
              className="mb-0 px-0"
              name="subject-group"
              label="General"
              id="General"
              checked={subject === 'General'}
              onClick={handleRadioChange}
            />
            <Form.Check
              type="radio"
              className="mb-0 px-0"
              name="subject-group"
              label="Prayer"
              id="Prayer"
              checked={subject === 'Prayer'}
              onClick={handleRadioChange}
            />
            <Form.Check
              type="radio"
              className="mb-0 px-0"
              name="subject-group"
              label="Website Issue/Request"
              id="Website"
              checked={subject === 'Website'}
              onClick={handleRadioChange}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="message">
            <Form.Label className="mb-0 form-label_small">Message</Form.Label>
            <Form.Control className="text-input_plain" as="textarea" rows="5" required placeholder="Ex: What time is the church open" />
            <Form.Control.Feedback type="invalid">Please add a message</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="file">
            <Form.File id="fileId" custom style={{width: '100%'}}>
              <Form.File.Input isValid />
              <Form.File.Label data-browse="Choose File">Upload a file (optional)</Form.File.Label>
              <Form.Control.Feedback type="invalid">You did it!</Form.Control.Feedback>
            </Form.File>
          </Form.Group>
        </Form.Row>
        <Button type="submit">Send</Button>
      </Form>
    )
  else if (!strapiCase.success)
    return (
      <>
        <p style={{ color: '#bd0000' }}>There was an error handling your request.</p>
        <p style={{ color: '#bd0000' }}>Either refresh the page and try again or contact us directly using email or phone.</p>
      </>
    )
  else if (strapiCase.success)
    return (
      <>
        <p>Your message has been sent successfully</p>
        <p>Contact request #{strapiCase.data.id}</p>
        {getUser().id ? <p><Link to="/dashboard?tab=link-1">Go to my contact requests</Link></p> : ``}
      </>
    )
}


export default ContactForm


const getUserFullName = () => {
  let names = []
  if (getUser() && getUser().name) {
    !!getUser().name.first_name && names.push(getUser().name.first_name)
    !!getUser().name.last_name && names.push(getUser().name.last_name)
  }
  return names.join(' ')
}