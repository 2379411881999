import React from "react"
import { navigate, Link } from "gatsby"
import Layout from '../components/layout'
import contactImage from '../assets/img/contact.jpg'


import ContactForm from '../components/contactForm'


class ContactPage extends React.Component {
  constructor() {
    super()
    this.state = { submitted: false }
  }

  render() {

    return (
      <Layout title={'Contact Us'} subtitle={'HRBC'}>

        <section className="page-section about-heading">
          <div className="container">
            <img clsass="img-fluid rounded about-heading-img mb-3 mb-lg-0" src={contactImage} width="100%" alt="contact image" />
            <div className="about-heading-content">
              <div className="row">
                <div className="col-xl-9 col-lg-10 mx-auto">
                  <div className="bg-faded rounded p-5">
                    <h2 className="section-heading text-center mb-4">
                      <span className="section-heading-upper">Our</span>
                      <span className="section-heading-lower">Contact Info</span>
                    </h2>
                    <div className="row">
                      <div className="col-md-5">
                        <ul className="contact-info">
                          <li className="location" data-toggle="tooltip" title="Map"><i className="fa fa-location-arrow" style={{ color: '#33383a' }}></i><a href="http://www.google.com/maps?saddr=My+Location&amp;daddr=Harmony+Road+Baptist+Church,+226+Harmony+Rd+S,+Oshawa,+ON+L1H+6T7" target="_blank">226 Harmony Rd. South, <br /> Oshawa, ON, Canada<br />L1H 6T7</a></li>
                          <li className="phone"><i className="fa fa-phone" style={{ color: '#33383a' }}></i><a href="tel://9057282322" data-toggle="tooltip" title="Phone">905-728-2322</a></li>
                          <li className="envelope"><i className="fa fa-envelope" style={{ color: '#33383a' }}></i><a href="mailto:harmonychurch@rogers.com" data-toggle="tooltip" title="Email">harmonychurch@rogers.com</a></li>
                          <li className="code"><i className="fa fa-code" style={{ color: '#33383a' }}></i><a href="mailto:webinfo@harmony-road.com" data-toggle="tooltip" title="Web inquiries">webinfo@harmony-road.com</a></li>
                          <li className="monitor"><i className="fa fa-desktop" style={{ color: '#33383a' }}></i><a href="http://www.harmony-road.com" data-toggle="tooltip" title="Web address" target="_blank">https://www.harmony-road.com</a></li>
                        </ul>
                      </div>
                      <div className="col-md-7">

                        {this.state.submitted ? 
                        <></> : 
                        <ContactForm />}
                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>





        {/* <section className="page-section about-heading" style={{ paddingTop: '5rem' }}>
          <Container>
            <div className="about-heading-content">
              <Row>
                <Col xl={9} lg={10} className="mx-auto">
                  <div className="bg-faded rounded p-5">
                    <h2 className="section-heading text-center mb-4">
                      <span className="section-heading-lower">Login</span>
                    </h2>
                    <Row>
                      <Col md={6} className="mx-auto">
                        <form
                          method="post"
                          onSubmit={event => {
                            this.handleSubmit(event)
                          }}
                        >
                          <Row noGutters>
                            {this.state.message}
                          </Row>
                          <Row noGutters>
                            <Col className="mb-3">
                              <input /
                                type="text"
                                name="username"
                                placeholder="Email"
                                value={this.state.username}
                                onChange={this.handleUpdate}
                                style={{ width: '100%' }}
                              />
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col className="mb-3">
                              <input /
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleUpdate}
                                style={{ width: '100%' }}
                              />
                              <br />
                            </Col>
                          </Row>
                          <Row noGutters>
                            <input /
                              type="submit"
                              name="submit"
                              value="Login"
                              className="btn btn-primary"
                              onsubmit="return false"
                              style={{ width: '100%' }}
                            />
                          </Row>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section> */}
      </Layout>
    )
  }
}

export default ContactPage





